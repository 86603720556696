<template>
  <div>
    <p class="h4 pt-4 pb-4">泛函公告-历史公告</p>
    <el-table
      :data="lists"
      border
      style="width: 100%">
      <el-table-column label="#" type="index" width="80" align="center"></el-table-column>
      <el-table-column label="公告标题" prop="header" align="center"></el-table-column>
      <el-table-column label="发布时间" width="200" align="center">
        <template slot-scope="scope">
          <span>{{ formatTime(scope.row.created) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button style="color:#3775F6" type="text" @click="$router.push('/tips-admin/announce/fahan/detail?id=' + scope.row.id)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getAfficheList } from '@/api/team-info'
import { formatTime } from '@/lib/format'
export default {
  name: 'history',
  data () {
    return {
      lists: [],
      formatTime: formatTime
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getLists()
    })
  },
  methods: {
    getLists () {
      const data = {
        teamid: 0,
        status: 2
      }
      getAfficheList(data).then(
        ({ data = {} }) => {
          this.lists = data
        }
      )
    }
  }
}
</script>
<style lang="less">
.dialog-item {
  display: flex;
  flex-wrap: wrap;
  p {
    width: 50%;
  }
}
</style>
